<template>
  <div class="blog">
    <sui-segment class="blogpost-overview-loader" v-if="!blog.loaded">
      <sui-dimmer active inverted>
        <sui-loader indeterminate>{{ loading.status }}</sui-loader>
      </sui-dimmer>
    </sui-segment>
    <div class="blogpost" v-if="blogpost.loaded">
      <div class="ui segment">
        <div class="close-blogpost" @click="closeBlogPost()">
          <sui-icon name="close" size="large" />
        </div>
        <component :is="blogpost.module" :author="getAuthorById(blogpost.meta.authorId)" :publishedFmt="convertPublishedDate(blogpost.meta.published)"></component>
      </div>
    </div>
    <div class="blog-overview" v-if="!blogpost.loaded && blog.loaded">
      <a :if="blog.loaded" @click="showBlogPost(index + 1)" v-for="(blogpost, index) in blog.posts" v-bind:key="blogpost.meta.name" class="ui card article-preview">
        <div class="content">
          <div class="header">
            {{ blogpost.meta.title }}
          </div>
          <div class="meta">
            <span class="category">
              {{ blogpost.meta.category }}
            </span>
          </div>
          <div class="description">
            <p>
              {{ blogpost.meta.description }}
            </p>
          </div>
        </div>
        <div class="extra content">
          <div class="left floated">
            <span :title="blogpost.meta.published">{{ convertPublishedDate(blogpost.meta.published) }}</span>
          </div>
          <div class="right floated author">
            <img class="ui avatar image" :src="getBlogPostThumbnailSrc(getAuthorById(blogpost.meta.authorId).avatar)">
          </div>
        </div>
      </a>
      <!--
        TODO: Implement pagination if blog posts exceed more than ~5-6
      <div class="ui fluid pagination menu articles-navigation">
          <a class="item">
            <i class="caret left icon"></i>
          </a>
          <div class="disabled item">...
          </div>
          <a class="item active">10
          </a>
          <a class="item"> 11
          </a>
          <a class="item">12
          </a>
          <a class="item">12
          </a>
          <a class="item">12
          </a>
          <a class="item">
              <i class="caret right icon"></i>
          </a>
      </div>
      -->
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { marked } from 'marked'
import moment from 'moment'
import router from '../router/index'

export default {
  name: 'Blog',
  data () {
    return {
      blog: {
        loaded: false,
        posts: []
      },
      blogpost: {
        loaded: false,
        module: false
      },
      loading: {
        status: 'Preparing overview...'
      }
    }
  },
  watch: {
    $route (to, from) {
      const fromName = from.name.toLowerCase()
      const toName = to.name.toLowerCase()

      if (fromName === 'blog posts' && toName !== 'blog posts') {
        this.blogpost.loaded = false
        this.blogpost.module = false
      } else if (fromName === 'blog' && toName === 'blog posts' && !this.blogpost.loaded) {
        // Show blog post if blogpost is not loaded, but current path is blog posts
        this.showBlogPost(to.params.id)
      }
    }
  },
  mounted () {
    this.initAllBlogPosts()

    if (this.currentRoute.name.toLowerCase() === 'blog posts' && 'id' in this.currentRoute.params) {
      this.showBlogPost(this.currentRoute.params.id)
    }
  },
  computed: {
    currentRoute () {
      return this.$route
    }
  },
  methods: {
    initAllBlogPosts () {
      const allFiles = (ctx => {
        const keys = ctx.keys()
        const values = keys.map(ctx)

        return values
      })(require.context('/blog/blogposts', true, /^.*\.vue$/))

      allFiles.forEach((element, index) => {
        const componentName = element.default.name.replaceAll(' ', '')

        element.default.name = componentName

        allFiles[index] = {
          meta: element.default.data(),
          component: Vue.component(componentName, element.default)
        }
      })

      this.blog.posts = allFiles
      this.blog.loaded = true
    },
    showBlogPost (index) {
      // Index starts at 1 for blogpost, so define actual index for allBlogPosts
      const actualBlogPostIndex = index - 1
      const routePath = '/blog/posts/' + index

      if (typeof (this.blog.posts[actualBlogPostIndex]) === 'undefined') {
        if (this.currentRoute.name === 'Blog Posts') {
          router.push({ name: 'Blog' })
        }

        return
      }

      // Route to path for browser history
      if (this.currentRoute.path !== routePath) {
        router.push({ path: routePath })
      }

      this.blogpost.loaded = true
      this.blogpost.meta = this.blog.posts[actualBlogPostIndex].meta
      this.blogpost.module = this.blog.posts[actualBlogPostIndex].component

      // Update document title
      document.title = this.blog.posts[actualBlogPostIndex].meta.title
    },
    closeBlogPost () {
      this.blogpost.loaded = false
      this.blogpost.meta = false
      this.blogpost.module = false

      router.push({
        name: 'Blog'
      })
    },
    getAuthorById (authorId) {
      try {
        return require(`../../blog/authors/${authorId}.json`)
      } catch {
        return require('../../blog/authors/default.json')
      }
    },
    convertPublishedDate (date) {
      return moment(date).fromNow()
    },
    renderMarkdown (content) {
      return marked(content)
    },
    getBlogPostThumbnailSrc (src) {
      return require('../assets/blog/img/' + src)
    },
    headHome: () => {
      router.push({
        name: 'Home'
      })
    }
  }
}
</script>
<style scoped>
a {
  font-size: 18px;
  color: red;
  cursor: pointer;
}

.blog:not(.title) {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.blog-overview {
  width: 480px;
  max-width: 90%;
  margin: 0 auto;
}

.blog-overview .article-preview {
  width: 100%;
}

.blog .article-preview .description > p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.blog .blogpost-overview-loader {
  height: 100px;
  border: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.blog .blogpost {
  max-width: 90%;
  margin: 0 auto;
}
.blog .blogpost:deep(p) {
  font-size: 19px;
}

.blog .blogpost:deep(.title) {
  text-align: center;
}

.blog .blogpost .close-blogpost {
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
  color: #b9b9b9;
}

.blog .blogpost .close-blogpost:hover {
  color: #000;
  transition: 0.3s;
}
</style>
