<template>
    <div>
      <h1 is="sui-header" class="title">
        {{ title }}
        <sui-header-subheader>
        Author: {{ author.name }} &mdash; Category: {{ category }} &mdash; <span :title="published">Published: {{ publishedFmt }}</span>
      </sui-header-subheader>
      </h1>
      <p>
        There's been some times when a customer has requested some custom development, as well as the need to run the tool themselves.<br><br>
        To keep this rather simple, and not give them a headache trying to install Python as well as the required packages,
        a standalone executable is more up their alley.<br><br>
        In order to do this you can use the PyInstaller package. Install it as such:<br>
        <code>pip install pyinstaller</code>
        Once pyinstaller has been installed, simply run it inside your project folder with the following command:<br>
        <code>pyinstaller --onefile cli.py</code>
        After the command has finished successfully, a standalone executable should be located in <code>dist/</code>
      </p>
    </div>
</template>
<script>
export default {
  name: 'First blog post',
  data: () => {
    return {
      title: 'Creating a standalone executable',
      published: '2022-11-04 20:42:02+01:00',
      category: 'Python development',
      authorId: 1,
      description: 'Short tutorial on how to create a standalone executable'
    }
  },
  props: {
    created: String,
    author: Object,
    publishedFmt: String
  }
}
</script>
